import _ from "lodash"
import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import CustomSlider from "../CustomSlider/CustomSlider"
import PropertySliderCard from "../PropertySliderCard/PropertySliderCard"
import "./TeamProperty.scss"
import useDeviceMedia from "../../hooks/useDeviceMedia"

const TeamProperty = ({ teamInfo, teamData }) => {
  
  const {isMobile} = useDeviceMedia()

  const splitName = teamInfo?.name?.split(" ")
  const firstName = splitName?.length > 0 && splitName[0]

  return (
    <>
      {teamData?.length > 0 && (
        <div className="team-property-wrapper">
          <Container>
            <div className="team-property-card">
              <h2 className="team-prop-title">{firstName}'s Listings</h2>
              <CustomSlider
                showArrows
                className="team-prop-slider"
                slidecount={isMobile?1:4}
              >
                {teamData.length > 0 &&
                  teamData.map((item, index) => {
                    const imageUrl =
                      item.node?.images?.length > 0 &&
                      item.node.images[0]

                    const imageLength = item.node?.images?.length

                    return (
                      <PropertySliderCard
                        title={item.node?.title}
                        image={imageUrl}
                        imageCount={imageLength}
                        imageList={item.node?.images}
                        display_address={item.node?.display_address}
                        price={item.node?.price}
                        bathroom={item.node?.bathroom}
                        bedroom={item.node?.bedroom}
                        floorarea_min={item.node?.floorarea_min}
                        floorarea_type={item.node?.floorarea_type}
                        search_type={item.node?.search_type}
                        department={item.node?.department}
                        strapi_id={item?.node?.strapi_id}
                        slug={item.node?.slug}
                        crm_id={item.node?.crm_id}
                        status={item.node?.status}
                        imagetransforms={item.node?.imagetransforms}
                        slider_name="team-property-slider"
                        ggfx_results={item.node?.ggfx_results}
                      />
                    )
                  })}
              </CustomSlider>
            </div>
          </Container>
        </div>
      )}
    </>
  )
}

export default TeamProperty
